import { AlertCircle } from "lucide-react";

import { cn, ErrorMessage } from "../lib";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";

export type ErrorAlertProps = {
  message: ErrorMessage;
  className?: string;
};

export const ErrorAlert = ({ message, className }: ErrorAlertProps) => {
  const { title, description } = message;
  return (
    <Alert variant="destructive" className={cn("border-2 bg-red-50", className)}>
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>{title}</AlertTitle>
      {description && (
        <AlertDescription className="whitespace-pre-wrap">{description}</AlertDescription>
      )}
    </Alert>
  );
};
