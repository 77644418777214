/**
 * Flatter tabs variant with a white background.
 */
import React from "react";

import { cn } from "../lib";
import { TabsList, TabsTrigger } from "./ui/tabs";

export const FlatTabsList = React.forwardRef<
  React.ElementRef<typeof TabsList>,
  React.ComponentPropsWithoutRef<typeof TabsList>
>(({ className, ...props }, ref) => (
  <TabsList
    ref={ref}
    className={cn("h-auto gap-6 rounded-none bg-white p-0", className)}
    {...props}
  />
));
FlatTabsList.displayName = "FlatTabsList";

export const FlatTabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsTrigger>,
  React.ComponentPropsWithoutRef<typeof TabsTrigger>
>(({ className, ...props }, ref) => (
  <TabsTrigger
    ref={ref}
    className={cn(
      "transition-none focus-visible:ring-0", // Disable focus ring
      "rounded-none border-b-2 border-transparent px-0 py-3",
      "data-[state=active]:border-indigo-500 data-[state=active]:shadow-none",
      className
    )}
    {...props}
  />
));
FlatTabsTrigger.displayName = "FlatTabsTrigger";
