import * as React from "react";

import { Loader } from "./Loader";
import { Button, ButtonProps } from "./ui/button";

export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}

/**
 * Extend Button to show a loading indicator (in place of children)
 * when the `loading` prop is true.
 */
const LoadingButton = React.forwardRef<HTMLButtonElement, LoadingButtonProps>(
  ({ loading = false, children, ...props }, ref) => {
    return (
      <Button ref={ref} {...props}>
        {loading ? <Loader className="text-white" /> : children}
      </Button>
    );
  }
);
LoadingButton.displayName = "Button";

export { LoadingButton };
