import {
  Button,
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@holdenmatt/shadcn-kit";
import { logError } from "@holdenmatt/ts-utils";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

import { SIGNED_IN_HOME_URL } from "@/constants";

type ErrorPageProps = {
  error: Error;
  title?: string;
  subtitle?: string;

  /**
   * If a NextJS reset function is provided, show a button for the user to
   * try rerendering the affected component tree.
   *
   * Otherwise, show a button to refresh the page.
   */
  reset?: () => void;
};

/**
 * Render an error page.
 */
const ErrorPage = ({
  error,
  title = "Oops! 😕",
  subtitle = "Something went wrong. Our team has been notified.",
  reset,
}: ErrorPageProps) => {
  const router = useRouter();

  useEffect(() => {
    logError(error);
  }, [error]);

  const handleReload = reset ? reset : router.refresh;

  return (
    <div className="flex min-h-screen items-center justify-center">
      <Card className="w-1/3 min-w-[350px]">
        <CardHeader>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{subtitle}</CardDescription>
        </CardHeader>
        <CardFooter>
          <div className="flex gap-4">
            <Button onClick={handleReload}>Reload page</Button>
            <Button variant="secondary" asChild>
              <a href={SIGNED_IN_HOME_URL}>Go home &nbsp; &rarr;</a>
            </Button>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ErrorPage;
