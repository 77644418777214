import React from "react";

import {
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectRoot,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

type Props = React.ComponentPropsWithoutRef<typeof SelectRoot> & {
  children: React.ReactNode;
  label?: string;

  /**
   * Classes applied to the SelectTrigger.
   */
  className?: string;

  /**
   * Classes applied to the SelectContent.
   */
  contentClassName?: string;

  /**
   * Control the value shown (defaults to the selected value).
   */
  selectValueChildren?: React.ReactNode;
};

/**
 * Render a simple Select component, abstracting away details.
 */
export const Select = React.forwardRef<React.ElementRef<typeof SelectTrigger>, Props>(
  (
    { children, label, className, contentClassName, selectValueChildren, ...props },
    forwardedRef
  ) => {
    return (
      <SelectRoot {...props}>
        <SelectTrigger ref={forwardedRef} className={className}>
          <span className="truncate pr-2">
            <SelectValue placeholder={label}>{selectValueChildren}</SelectValue>
          </span>
        </SelectTrigger>
        <SelectContent className={contentClassName}>
          <SelectGroup>
            {label && <SelectLabel>{label}</SelectLabel>}
            {children}
          </SelectGroup>
        </SelectContent>
      </SelectRoot>
    );
  }
);
Select.displayName = "Select";
