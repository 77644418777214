import { isDevelopment } from "@holdenmatt/ts-utils";
import colors from "tailwindcss/colors";
import type { PackageJson } from "type-fest";

// App metadata
export const APP_TITLE = "ChartPilot";
export const APP_DESCRIPTION = "Your charting copilot";
export const CHART_WATERMARK = `Made with ${APP_TITLE}`;

const packageJson = require("../package.json") as PackageJson;
if (!packageJson.version) throw new Error("No version found in package.json");
export const APP_VERSION: string = packageJson.version;

export const DEFAULT_TITLE = "Untitled";

//-- Events --//

export type CUSTOM_EVENTS = {
  LOAD_CHART: never;
  COMPLETE_GETTING_STARTED: never;
};

//-- URLs --//

export const SIGNED_IN_HOME_URL = "/projects/recent";

/**
 * Get the URL protocol/host (without path) from a Vercel env var.
 *
 * To use across environments, add:
 *    .env.local        NEXT_PUBLIC_VERCEL_URL=http://localhost:XXXX
 *    In Vercel, set:   NEXT_PUBLIC_VERCEL_URL=<your site> (Production only)
 */
export const getBaseUrl = (): string => {
  const vercelUrl = process.env.NEXT_PUBLIC_VERCEL_URL;
  if (!vercelUrl) {
    throw new Error("Missing env var: NEXT_PUBLIC_VERCEL_URL");
  }

  if (isDevelopment()) {
    return `http://${vercelUrl}`;
  } else {
    return `https://${vercelUrl}`;
  }
};

/**
 * Get the project URL for a given projectId.
 */
export const getProjectUrl = (projectId: string): string => {
  return `${getBaseUrl()}/project/${projectId}`;
};

//-- Styles --//

// Colors used in color picker swatches.
const red = colors.red[600];
const orange = colors.orange[500];
const yellow = colors.yellow[300];
const green = colors.green[500];
const blue = colors.blue[500];
const purple = colors.indigo[500];
const black = colors.gray[900];
const brown = `hsl(35, 59.0%, 27.4%)`;
const gray = colors.gray[400];
const white = colors.white;

export const DEFAULT_SWATCHES = [
  { name: "Red", value: red },
  { name: "Orange", value: orange },
  { name: "Yellow", value: yellow },
  { name: "Green", value: green },
  { name: "Blue", value: blue },
  { name: "Purple", value: purple },
  { name: "Black", value: black },
  // { name: "Brown", value: brown },
  // { name: "Gray", value: gray },
  { name: "White", value: white },
];
export const DEFAULT_GRADIENTS = [
  // Harmonius
  { color: purple, color2: green },
  { color: red, color2: yellow },
  { color: green, color2: blue },

  // Contrasting
  { color: blue, color2: yellow },
  { color: orange, color2: blue },
  { color: purple, color2: yellow },

  // Monochromatic
  { color: gray, color2: black },
  { color: yellow, color2: gray },
];
