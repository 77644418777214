import { cn } from "@holdenmatt/shadcn-kit";

/**
 * An anchor link to an external webpage, which opens in a new tab by default.
 */
export const ExternalLink = ({
  href,
  children,
  className,
  target = "_blank",
}: {
  href: string;
  children: React.ReactNode;
  className?: string;
  target?: string;
}) => (
  <a href={href} className={cn("text-indigo-400", className)} target={target}>
    {children}
  </a>
);
