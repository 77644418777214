import React from "react";

import { cn } from "../lib";

export type InputWrapperProps = {
  children: React.ReactNode;

  /**
   * Label above the wrapped element.
   */
  label?: React.ReactNode;

  /**
   * Description below it (only shown if there's no error).
   */
  description?: React.ReactNode;

  /**
   * Error message.
   */
  error?: React.ReactNode;

  className?: string;
};

/**
 * Wrap an element (such as an <Input> or <Textarea>) with optional label, description, or error elements.
 *
 * Styled like shadcn examples (but simpler than using form):
 * https://ui.shadcn.com/docs/components/textarea
 */
export const InputWrapper = ({
  children,
  label,
  description,
  error,
  className,
}: InputWrapperProps) => {
  return (
    <div className={cn("flex w-full flex-col gap-3", className)}>
      {label && <p className="text-muted-foreground text-sm">{label}</p>}
      {children}
      {error ? (
        <p className="text-destructive text-sm">{error}</p>
      ) : description ? (
        <p className="text-muted-foreground text-sm">{description}</p>
      ) : null}
    </div>
  );
};
